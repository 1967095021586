import React from 'react';
import Layout from '../components/layout';
import Message from '../components/contactform/message';

const Contact = () => {
  return (
    <Layout pageTitle='Contact Page'>
      <Message />
    </Layout>
  );
};

export default Contact;
